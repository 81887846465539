<template>
  <div>
 <span
   @click="legalModel=true"
   class="ml-12"
   style="color: rgba(26,26,26,.5); font-size: 13px !important; cursor: pointer"
 >
                Legales
              </span>

    <v-dialog
      v-model="legalModel"
      max-width="500"
      bottom
      :overlay-opacity="0.15"
      content-class="shadow-md rounded-lg"
    >
      <v-card class="py-6 rounded-lg">

        <div class="d-flex mb-4 px-4">
          <span class="font-rubik" style="flex: 1">Legales</span>
          <v-icon size="20" @click="legalModel=false">{{ mdiClose() }}</v-icon>
        </div>
        <v-divider></v-divider>

        <table class="px-4 mt-4 mb-2">
          <tr>
            <td class="pr-2">
              <v-icon size="17">{{ mdiFileDocument() }}</v-icon>
            </td>
            <td>
              <p class="mb-0" style="font-size: 13px !important;">
                Consulta las políticas de
                <a
                  target="_blank"
                  href="https://examenselectivitat.cat/condicions-generals">
                  condiciones generales
                </a>
                y
                <a
                  target="_blank"
                  href="https://examenselectivitat.cat/politica-de-privicitat">
                  política de privacidad
                </a>
                de examenselectivitat.
              </p>
            </td>
          </tr>
        </table>


        <table class="px-4 mt-4 mb-2">
          <tr>
            <td class="pr-2">
              <v-icon size="17">{{ mdiCreditCard() }}</v-icon>
            </td>
            <td>
              <p class="mb-0" style="font-size: 13px !important;">
                Stripe procesa todos los pagos de forma segura. Consulta las
                <a
                  target="_blank"
                  rel="nofollow"
                  href="https://stripe.com/es/legal/end-users">
                  políticas de condiciones
                </a>
                y
                <a
                  target="_blank"
                  rel="nofollow"
                  href="https://stripe.com/es/privacy">
                  privacidad
                </a>
                de Stripe.
              </p>
            </td>
          </tr>
        </table>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mdiClose, mdiCreditCard, mdiFileDocument} from "/src/assets/mdi.json";

export default {
  name: "Legales",
  methods: {
    mdiCreditCard() {
      return mdiCreditCard
    },
    mdiClose() {
      return mdiClose
    },
    mdiFileDocument() {
      return mdiFileDocument
    }
  },
  data() {
    return {
      legalModel: false,

    }
  }
}
</script>

<style scoped>

</style>
