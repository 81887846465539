<template>
  <div>
    <div :style="step === 1 ? 'opacity: 1' : 'opacity: 0'"

         class="d-flex flex-column text-start justify-start align-start"
    >

      <v-btn
        @click="$emit('update:step', 0)"
        class="text-none px-0 no-background-hover"
        style="margin-left: -10px"
        text>
        <v-icon small color="rgba(26, 26, 26, 0.5)">{{ mdiChevronLeft() }}</v-icon>
        <span class="font-rubik" style="color: rgba(26, 26, 26, 0.5); font-size: 12px">Volver</span>
      </v-btn>


      <span
        v-if="$vuetify.breakpoint.mdAndUp"
        class="font-rubik font-weight-medium mb-2" style="font-size: 20px">
                    Pago con tarjeta
                </span>

    </div>

    <!---- TARJETA -------->
    <div
      :style="step === 1 ? 'opacity: 1' : 'opacity: 0'"
      id="payment-element" class="mt-4 mb-10"></div>


    <div :style="step === 1 ? 'opacity: 1' : 'opacity: 0'">

      <v-btn
        :loading="loading"
        color="primary"
        @click="pay"
        height="44"
        style="border-radius: 6px; color: #A7C1FF; font-size: 16px; letter-spacing: normal"
        class="element-shadow text-none"
        block
      >
        Pagar
      </v-btn>

    </div>
  </div>
</template>

<script>
import {mdiChevronLeft} from "/src/assets/mdi.json";

export default {
  name: "CreditCardForm",
  props: {
    step: Number,
    intent_id: String,
    stripe: Object,
    elements: Object,
    paymentInfo: Object,
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    async pay(e) {
      this.loading = true;
      e.preventDefault();

      const appearance = {
        theme: 'stripe',
      };


      const {error} = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          return_url: process.env.VUE_APP_URL + '/dossiers-selectivitat/success?',
          // Make sure to change this to your payment completion page
          // return_url: 'https://examenselectivitat.cat/dossiers-selectivitat/success?',
        },
      });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        alert("Hi ha hagut un error: "+ error.message);

        await this.axios.post('/payment-error', {
          error: error.type + ': '+ error.message
        })
      } else {
        alert("Hi ha hagut un error: "+ error.message);

        await this.axios.post('/payment-error', {
          error: error.type + ': '+ error.message
        })
      }

      this.loading = false;

    },

    // ----- ICONS -----
    mdiChevronLeft() {
      return mdiChevronLeft
    }
  }
}
</script>

<style scoped>

</style>
