<template>
  <v-row>
    <v-col v-for="(item, i) in shippings"
           :key="`item-${i}`"
           :class="i === 0 ? 'pr-2' : 'pl-2'"
           cols="6"
    >
      <div
        @click="selected = i"
        class="d-flex flex-column pa-3 align-start element element-shadow rounded-lg"
        style="cursor: pointer; flex: 1; position: relative"
        :class="i === selected ? 'element-border--active' : 'element-border'"
        :style="'color: ' + (i === selected ? '#1872E1' : '#6D6E78')"
      >

        <v-icon
          :color="i === selected ? '#1872E1' : '#6D6E78'"
          class="mb-1"
        >{{ i === 0 ? mdiTruck() : mdiTruckFast() }}
        </v-icon>

        <span
          v-if="item.title"
          class="font-weight-medium element-title"
          :style="'color: ' + (i === selected ? '#1872E1' : '#6D6E78')"
          style="font-size: 16px"
          v-text="item.title.replace('à', 'á')">
        </span>
        <span
          v-if="item.desc"
          style="font-size: 12px"
          class="font-rubik"
        >{{
            items.reduce((acc, i) => i.quantity + acc, 0) > 1 ? item.desc.replace('3 dies', '2 dies') : item.desc }}</span>


        <v-chip
          v-if="item.price !== null"
          :color="i === selected ? '#1872E1' : 'grey lighten-4'"
          small
          style="position: absolute; right: 8px; top: 10px;">
          <span
            v-if="item.price > 0"
            :style="'color: ' + (i === selected ? 'white' : '#6D6E78')"
            style="font-size: 12px"
            class="font-rubik"
          >€{{ item.price.toFixed(2) }}</span>

          <span
            v-else
            :style="'color: ' + (i === selected ? 'white' : '#6D6E78')"
            style="font-size: 12px"
            class="font-rubik"
          >Gratis</span>
        </v-chip>

        <v-tooltip
          v-else
          color="black"
          bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              size="16"
              style="position: absolute; right: 8px; top: 10px;">

              {{ mdiInformation() }}
            </v-icon>
          </template>
          <span>Añade un correo postal para calcular el precio.</span>
        </v-tooltip>


      </div>
    </v-col>
  </v-row>
</template>

<script>
import {mdiInformation, mdiRadioboxBlank, mdiRadioboxMarked, mdiTruck, mdiTruckFast} from "/src/assets/mdi.json";

export default {
  name: "ShippingsMenu",
  props: {
    items: {
      type: Array,
      required: true,
    },
    shippings: {
      type: Array,
      required: true,
    },
    selectedShipping: Number,
    postalCode: String,
  },
  mounted() {
    setTimeout(() => {
      console.log("this.items", this.items)
      if (this.items.length === 0) {
        this.$router.push("/dossiers-selectivitat")
      }
    }, 500)
  },
  data() {
    return {
      selected: this.selectedShipping,
    }
  },
  methods: {
    mdiInformation() {
      return mdiInformation
    },
    mdiTruckFast() {
      return mdiTruckFast
    },
    mdiTruck() {
      return mdiTruck
    },
    mdiRadioboxMarked() {
      return mdiRadioboxMarked
    },
    mdiRadioboxBlank() {
      return mdiRadioboxBlank
    }
  },
  watch: {
    selected(val) {
      this.$emit("update:selectedShipping", val)
    }
  }

}
</script>

<style scoped>
.element:hover >>> .element-title {
  color: #0d1216;
}

.element-shadow {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02) !important;
}

.element-border {
  border: #E0E0E0 solid 1px;
}

.element-border--active {
  border: #1872E1 solid 1px;
  outline: 1px solid #1872E1;
}


</style>
