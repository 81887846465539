<template>
  <div style="width: 100%; height: 100%; background-color: white; position: relative">


    <div
      v-if="$vuetify.breakpoint.mdAndUp"
      style="position: absolute; right: 0; width: 50%; top: 0; height: 100%; box-shadow: 15px 0 30px 0 rgba(0,0,0,.18)">

    </div>


    <div class="pa-0">
      <!-------- ROW -------------------->
      <v-row style="height: 100%; width: 100%"
             no-gutters
      >
        <v-col cols="12" md="6"
               style="height: 100%; min-height: 100%"
               :class="$vuetify.breakpoint.mdAndUp ? 'justify-end' : ''"
               :style="$vuetify.breakpoint.mdAndUp ? 'padding-right: 80px' : ''"
               class="d-flex"
        >
          <left-side
            :loading="loading"
            :selected-shipping="selectedShipping"
            :items="items"
            :books="books"
            :shippings="shippings"
          ></left-side>
        </v-col>
        <v-col cols="12" md="6"
               style="height: 100%;"
               :style="$vuetify.breakpoint.mdAndUp ? 'padding:  0 80px' : ''"
        >

          <v-card style="height: 100%; width: 100%; background-color: white; max-width: 380px"
                  tile flat
                  color="transparent"
                  :class="$vuetify.breakpoint.mdAndUp ? 'pt-12' : 'mx-auto'"
          >

            <!---------------- Right Skeleton ----------------->
            <loading-right v-if="loading"></loading-right>


            <!---------------- Shipment Form ----------------->
            <shipment-form
              v-show="step===0 && !loading"
              :selected-shipping.sync="selectedShipping"
              :step.sync="step"
              :intent_id.sync="intent_id"
              :items="items"
              :books="books"
              :shippings="shippings"
              :stripe.sync="stripe"
              :elements.sync="elements"
              :paymentInfo.sync="paymentInfo"
              :province.sync="province"
            ></shipment-form>

            <!---------------- Credit Card Form ----------------->
            <credit-card-form
              :step.sync="step"
              :intent_id="intent_id"
              :stripe="stripe"
              :elements="elements"
              :paymentInfo.sync="paymentInfo"
            ></credit-card-form>


          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>

import LeftSide from "./left-side/LeftSide.vue";
import LoadingRight from "./right-side/LoadingRight.vue";
import ShipmentForm from "./right-side/forms/ShipmentForm.vue";
import CreditCardForm from "./right-side/forms/CreditCardForm.vue";

export default {
  name: "CheckOut",
  components: {
    CreditCardForm,
    ShipmentForm,
    LoadingRight,
    LeftSide

  },
  async mounted() {
    this.loading = true;
    try {
      await this.setItems();

      await this.fetchShippings()

    } finally {
      this.loading = false;
    }
  },
  data() {
    return {
      stripe: null,
      intent_id: null,
      elements: null,
      books: null,
      items: [],
      loading: false,
      step: 0,

      // -------- Shipments ------------
      shippings: [{"Name": "EC72", "price": null, "desc": "1-3 dies laborals", "title": "Estàndard"}, {
        "Name": "EC48",
        "price": 3,
        "desc": "1-2 dies laborals",
        "title": "Express"
      }],
      selectedShipping: 0,

      paymentInfo: {},
      province: "Barcelona",
    }
  },
  methods: {
    async setItems() {

      this.list = this.$cookies.get("cart");

      if (Object.keys(this.list).length === 0)
        await this.$router.push("/dossiers-selectivitat")

      const {data} = await this.axios.get('/shop-list');
      let books = []
      data.forEach(d => books = books.concat([...d.subjects, ...d.apunts]));
      //
      this.items = [];
      books.forEach(b => {
        if (this.list[b.book_id]) {
          b.quantity = this.list[b.book_id];
          b.offers = [];
          b.offerPrices = {
            offer: 0,
            normal: b.quantity
          };

          for (let i = 0; i < b.quantity; i++) {
            b.offers.push(false);
          }
          this.items.push(b)
        }
      })


    },
    async fetchShippings() {
      let pages = 0;
      let price = 0;
      let totalQuantity = 0;
      this.items.forEach(item => {
        price += (item.pages * 0.02 + 0.08) * item.quantity;
        pages += item.pages * item.quantity;
        totalQuantity += item.quantity;
      })

      // pages = Math.floor(pages * 2.54);
      pages = Math.round(pages / 2);

      const {data} = await this.axios.get(`/shop/shipping/${pages}?province=${this.province}`)
      this.shippings = data;

      const FREE_SHIPPING = true;
      const FREE_UNTIL_QUANTITY = 5;
      if (FREE_SHIPPING && totalQuantity >= FREE_UNTIL_QUANTITY) {
        const index = this.shippings.findIndex(s => s.title === "Estàndard");
        if (index > -1) this.shippings[index].price = 0.0;
      }

    }
  },
  watch: {
    province(val) {
      if (val) {
        this.fetchShippings()
      }
    }
  }
}
</script>

<style scoped>

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}


</style>
