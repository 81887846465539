<template>
  <div
       class="mt-8"
  >
    <v-skeleton-loader
      v-for="f in 3"
      width="100%"
      type="list-item-avatar-two-line"
      :boilerplate="false"
      style="opacity: 0.4"
    ></v-skeleton-loader>

    <v-skeleton-loader
      class="mt-10"
      width="100%"
      type="list-item"
      :boilerplate="false"
      style="opacity: 0.4"
    ></v-skeleton-loader>
  </div>
</template>

<script>
export default {
  name: "LoadingLeft"
}
</script>

<style scoped>

</style>
