<template>
  <div>
  <v-skeleton-loader
    width="100%"
    type="heading" class="mb-12 mt-12 title-skeleton"
    :boilerplate="false"
    style="opacity: 0.4"
  ></v-skeleton-loader>

  <div
    v-for="f in 2"
  >
    <v-skeleton-loader
      width="100%" height="40"
      type="image" class="my-8 title-skeleton"
      :boilerplate="false"
      style="opacity: 0.4"
    ></v-skeleton-loader>

    <v-skeleton-loader
      width="100%" height="80"
      type="image" class="my-8 title-skeleton"
      :boilerplate="false"
      style="opacity: 0.4"
    ></v-skeleton-loader>
  </div>
  <v-skeleton-loader
    width="100%" height="40"
    type="image" class="my-8 title-skeleton"
    :boilerplate="false"
    style="opacity: 0.4"
  ></v-skeleton-loader>
  </div>
</template>

<script>
export default {
  name: "LoadingRight"
}
</script>

<style scoped>

</style>
