<template>
  <div style="position: relative">
  <select
    id="checkout-select"
    class="checkout-field rounded-t-0 mb-0"
    style="width: 100%"
    @change="onChange"
    @focus="focused=true"
    @focusout="focused=false"
    :class="focused ? 'v-input--is-focused' : ''"
  >
    <option
      v-for="item in items" :value="item"
      :selected="selected===item"
    >
      {{item}}
    </option>
  </select>
    <v-icon style="position: absolute; right: 8px; top: 12px"
            size="22" color="#6D6E78"
    >{{mdiChevronDown()}}</v-icon>
  </div>
<!--  <v-select-->
<!--    v-model="model"-->
<!--    :items="items"-->
<!--    ref="input"-->
<!--    @focus="focused=true"-->
<!--    @blur="validateField"-->
<!--    class="checkout-field"-->
<!--    style="border-radius: 6px"-->
<!--    :menu-props="{ bottom: true, offsetY: true }"-->
<!--    :append-outer="mdiChevronDown()"-->
<!--    :rules="[v => !!v || 'Aquest camp és obligatòri']"-->
<!--    outlined-->
<!--    :disabled="disabled"-->
<!--    :placeholder="label"-->
<!--    :hideDetails="hideDetails || !hasError"-->
<!--    :class="(hideDetails ? 'hide-details' : '') + ' ' +  (hasError ? 'has-error' : '') + ' ' +  (flat ? 'is-flat' : '')"-->
<!--  ></v-select>-->
</template>

<script>
import {mdiChevronDown} from "/src/assets/mdi.json";

export default {
  name: "TextInput",
  props: {
    items: Array,
    value: String,
    selected: String,
  },
  data() {
    return {
      focused: false,
      hasError: false,
      model: this.value
    }
  },
  methods: {
    mdiChevronDown() {
      return mdiChevronDown
    },
    validateField() {
      this.$nextTick(() => {
        if (!this.focused || this.$refs.input.validate()) {
          this.hasError = false
        } else {
          this.hasError = true
        }
      })
    },
    onChange() {
      // let value = e.value;
      let e = document.getElementById("checkout-select");
      let text = e.options[e.selectedIndex].text;
      console.log("onChange", text)

      this.$emit("update:value", text)
    }
  }
}
</script>

<style scoped>

.checkout-field.has-error {
  z-index: 20;
}

.checkout-field {
  max-height: 44.39px !important;
  min-height: 44.39px !important;
  margin-top: 0 !important;
  margin-bottom: 10px;
  font-size: 16px !important;
  padding: 0 12px;
  border-radius: 6px;
  border-width: thin !important;
  border-style: solid;
  border-color: #E0E0E0;
  color: hsla(0,0%,10%,.9) !important;
}

.checkout-field.is-flat >>> .v-input__slot {
  box-shadow: none !important;
}


.checkout-field.hide-details >>> .v-select__selections {
  max-height: 44.39px !important;
}


.checkout-field.v-input--is-focused {
  outline: 3px solid hsla(210, 96%, 45%, 25%);
  border-color: #99C9E9;
  color: hsla(0,0%,10%,.9) !important;
}

.checkout-field.has-error >>> fieldset {
  border-color: #FF5252 !important;
}

.checkout-field.v-input--is-disabled >>> input {
  color: hsla(0,0%,10%,.9) !important;
}

</style>
