<template>
  <v-text-field
    v-model="model"
    ref="input"
    style="border-radius: 6px"
    class="checkout-field"
    dense
    :rules="rules"
    outlined color="black"
    :type="type ? type : ''"
    :disabled="disabled"
    :placeholder="label"
    :hide-details="noText ? true : 'auto'"
    required
  ></v-text-field>
</template>

<script>
export default {
  name: "TextInput",
  props: {
    rules: Array,
    type: String,
    label: String,
    value: String,
    noText: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      model: this.value
    }
  },
  watch: {
    value(val) {
      this.model = val;
    },
    model(val) {
      this.$emit('update:value', val);
    }
  }
}
</script>

<style scoped>

.no-text >>> .v-messages__message {
  display: none !important;
}

.checkout-field.error--text {
  z-index: 10;
}

.checkout-field.v-input--is-focused {
  z-index: 20;
}

.checkout-field >>> .v-input__slot {
  min-height: 44.39px !important;
  margin-top: 0 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
  margin-bottom: 6px;
}

.checkout-field >>> .v-text-field__details {
  padding-left: 0 !important;
}

.checkout-field >>> .v-messages__message {
  font-size: 12px !important;
}

.checkout-field.is-flat >>> .v-input__slot {
  box-shadow: none !important;
}

.checkout-field.v-input--is-focused >>> .v-input__slot {
  outline: 3px solid hsla(210, 96%, 45%, 25%);
}

.checkout-field >>> .v-input__control {
  min-height: 44.39px !important;
  padding: 0 !important;
  margin: 0 !important;
}

.checkout-field.v-input--hide-details >>> .v-input__control {
  max-height: 44.39px !important;
}

.checkout-field >>> input {
  font-size: 16px !important;
  caret-color: hsla(0, 0%, 10%, .9) !important;
}

.checkout-field >>> fieldset {
  border-width: thin !important;
  border-color: #E0E0E0;
}

.checkout-field.v-input--is-focused {
  z-index: 100;
}

.checkout-field.v-input--is-focused >>> fieldset {
  border-color: #8FB9F1;
  color: hsla(0, 0%, 10%, .9) !important;
}

.checkout-field.error--text >>> fieldset {
  border-color: #FF5252 !important;
}

.checkout-field.v-input--is-disabled >>> input {
  color: hsla(0, 0%, 10%, .9) !important;
}

</style>
