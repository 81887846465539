<template>
  <div>
<span
  @click="contactModel=true"
  class="ml-12"
  style="color: rgba(26,26,26,.5); font-size: 13px !important; cursor: pointer"
>
                Contacto
              </span>

    <v-dialog
      v-model="contactModel"
      max-width="500"
      bottom
      :overlay-opacity="0.15"
      content-class="shadow-md rounded-lg"
    >
      <v-card class="py-6 rounded-lg">

        <div class="d-flex mb-4 px-4">
          <span class="font-rubik" style="flex: 1">Contacto</span>
          <v-icon size="20" @click="contactModel=false">{{ mdiClose() }}</v-icon>
        </div>
        <v-divider></v-divider>

        <table class="px-4 mt-4 mb-2">
          <tr>
            <td class="pr-2">
              <v-icon size="17">{{ mdiEmail() }}</v-icon>
            </td>
            <td>
              <p class="mb-0" style="font-size: 13px !important;">
                Si tienes alguna pregunta, envíanos un
                correo electrónico a
                <a
                  target="_blank"
                  href="mailto:contacte@examenselectivitat.cat">contacte@examenselectivitat.cat</a>
                .</p>
            </td>
          </tr>
        </table>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mdiClose, mdiEmail} from "/src/assets/mdi.json";

export default {
  name: "Contact",
  methods: {
    mdiEmail() {
      return mdiEmail
    },
    mdiClose() {
      return mdiClose
    }
  },
  data() {
    return {
      contactModel: false,

    }
  }
}
</script>

<style scoped>

</style>
