<template>
  <v-form
    v-model="valid"
    :lazy-validation="true"
    ref="form"
    class="mb-10"
  >

    <!-------- ENVIO -------------------->
    <span
      v-if="$vuetify.breakpoint.mdAndUp"
      class="font-rubik font-weight-medium" style="font-size: 20px">
                  Información del envío
              </span>

    <!-------- NAME ---------------------->
    <div class="mt-4 d-flex flex-column">
              <span
                style="font-size: 14px; color: #30313d"
                class="font-rubik"
              >
                Nombre
              </span>
      <text-input
        :value.sync="name"
        class="mt-1"
        :rules="[v => !!v || 'Este campo es obligatorio', v => (v && v.trim().length >= 6) || 'Debe tener al menos 6 caracteres', v => (v && v.trim().length <= 50) || 'El nombre no puede sobrepasar los 50 caracteres']"
      ></text-input>
    </div>

    <!-------- EMAIL -------------------->
    <div class="my-4 d-flex flex-column">
              <span
                style="font-size: 14px; color: #30313d"
                class="font-rubik"
              >
                Correo electrónico
              </span>
      <text-input
        :value.sync="email"
        class="mt-1"
        :rules="[v => !!v || 'Este campo es obligatorio', v => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v)|| 'El email no es válido']"
        type="email"
      ></text-input>
    </div>


    <!-------- DIRECCIÓ + ENVIAMENT ---------------------->
    <span
      style="font-size: 14px; color: #30313d"
      class="font-rubik"
    >
                Dirección del envío
              </span>

    <div class="mt-1 element-shadow rounded-lg">
      <!--              <text-input-->
      <!--                value="Espanya"-->
      <!--                class="mt-1 rounded-b-0"-->
      <!--                :disabled="true" flat-->
      <!--                :hide-details="true"-->
      <!--              ></text-input>-->

      <text-input
        :value.sync="address"
        class="rounded-b-0 mt-2"
        label="Dirección (calle, número, piso y puerta o local)"
        :rules="[v => !!v || 'Este campo es obligatorio', v => (v && v.length >= 6) || 'Debe tener al menos 6 caracteres', v => (v && v.length <= 100) || 'La dirección no puede sobrepasar los 100 caracteres']"
        flat
        :no-text="true"
        style="margin-top: -1px !important;"
      ></text-input>

      <div class="d-flex">
        <text-input
          :value.sync="postalCode"
          class="rounded-0"
          label="Codigo postal"
          :rules="[v => !!v || 'Este campo es obligatorio', v => /^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/.test(v)|| 'El código postal no es válido']"
          flat
          :no-text="true"
          style="margin-top: -1px !important;"
        ></text-input>
        <text-input
          :value.sync="city"
          class="rounded-0"
          label="Ciudad"
          :rules="[v => !!v || 'Este campo es obligatorio', v => (v && v.length <= 100) || 'La ciudad no puede sobrepasar els 100 caracteres']"
          :no-text="true"
          flat
          style="margin-top: -1px !important; margin-left: -1px !important;"
        ></text-input>

      </div>

      <div style="position: relative">
        <text-input
          :value.sync="telephone"
          class="rounded-0"
          label="Teléfono"
          :rules="[v => !!v || 'Este campo es obligatorio', v => /^[769][0-9]{8}$/.test(v)|| 'El número no es válido']"
          :no-text="true"
          style="margin-top: -1px !important"
          flat
        ></text-input>

        <v-tooltip
          color="black"

          bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              size="17"
              style="position: absolute; right: 10px; top: 14px"
            >{{ mdiInformation() }}
            </v-icon>
          </template>
          <p class="text-center mb-0"
             style="font-size: 14px !important;"
          >En el caso que el servicio de correos <br> tenga que contactar.</p>
        </v-tooltip>
      </div>

      <select-input
        :value.sync="provinceComp"
        class="rounded-t-0"
        label="Provincia"
        :items="provincies"
        selected="Barcelona"
        style="margin-top: -1px !important;"
      ></select-input>

    </div>


    <shippings-menu
      :selectedShipping.sync="selectedShipping2"
      ref="shippings"
      :shippings="shippings"
      :items="items"
      :postalCode.sync="postalCode"
      class="mb-3 mt-4"
    />

    <arrivada-enviament class="mt-2 mb-4"></arrivada-enviament>


    <v-alert
      v-if="quantity < 5"
      flat border="left" color="orange darken-1" type="success"
      class="mb-6">
      <span class="font-rubik" style="font-size: 15px">
        Afegeix {{ 5 - quantity }} {{ 5 - quantity === 1 ? 'dossier' : 'dossiers' }} més per obtenir l'enviament gratuït
      </span>
    </v-alert>


    <v-btn
      :loading="loading"
      color="primary"
      @click="validate"
      height="44"
      style="border-radius: 6px; color: #A7C1FF; font-size: 16px; letter-spacing: normal"
      class="element-shadow text-none"
      block
    >
      Continuar con el pago
    </v-btn>


    <v-dialog
      v-model="addressAlert"
      max-width="600">
      <v-card class="pa-10 pb-5">
        <span class="font-rubik" style="font-size: 16px">Comprova que la teva adreça inclou el <b>número del carrer i el pis </b>(si és el cas). </span>


        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary"
                 @click="addressAlert=false"
          >Acceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-form>


</template>

<script>
import {mdiInformation} from "/src/assets/mdi.json";
import TextInput from "./components/TextInput.vue";
import SelectInput from "./components/SelectInput.vue";
import ShippingsMenu from "./components/ShippingsSelector.vue";
import {loadStripe} from "@stripe/stripe-js";
import ArrivadaEnviament from "../../../components/ArrivadaEnviament.vue";

function debounce(fn, delay) {
  var timeoutID = null
  return function () {
    clearTimeout(timeoutID)
    var args = arguments
    var that = this
    timeoutID = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
  }
}

export default {
  name: "ShipmentForm",
  props: {
    items: Array,
    books: Array,
    shippings: Array,
    selectedShipping: Number,
    step: Number,
    intent_id: String,
    province: String,
    stripe: Object,
    elements: Object,
    paymentInfo: Object,
  },
  components: {
    ArrivadaEnviament,
    ShippingsMenu,
    TextInput,
    SelectInput
  },
  data() {
    return {
      provincies: ["Albacete", "Alicante", "Almería", "Asturias", "Ávila", "Badajoz", "Balears", "Barcelona", "Burgos", "Cáceres", "Cádiz", "Cantabria", "Castellón", "Ceuta", "Ciudad Real", "Córdoba", "Cuenca", "Gerona", "Granada", "Guadalajara", "Huelva", "Huesca", "Jaén", "La Coruña", "La Rioja", "Las Palmas", "León", "Lérida", "Lugo", "Madrid", "Málaga", "Melilla", "Murcia", "Navarra", "Orense", "Palencia", "Pontevedra", "Salamanca", "Segovia", "Sevilla", "Soria", "Sta.Cruz Tenerife", "Tarragona", "Teruel", "Toledo", "Valencia", "Valladolid", "Zamora", "Zaragoza",],
      loading: false,
      valid: true,
      selectedShipping2: this.selectedShipping,
      name: "",
      email: "",
      address: "",
      postalCode: "",
      city: "",
      telephone: "",
      addressAlert: false
    }
  },
  computed: {
    provinceComp: {
      get() {
        return this.province
      },
      set(val) {
        this.$emit("update:province", val)
      }
    },
    quantity() {
      let total = 0;
      this.items.forEach(i =>
        total += i.quantity
      )

      return total;
    }
  },
  methods: {
    checkAddress() {
      const addressHasNumber = this.address.match(/.*\d+.*\d+.*\d+.*/g)
      console.log("addressHasNumber", addressHasNumber)
      if (!addressHasNumber) {
        this.addressAlert = true;
      }

    },
    async validate() {
      this.$refs.form.validate()

      const nameValid = this.name.trim().length >= 6 && this.name.trim().length <= 50
      const emailValid = this.email.length > 0 && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.email)
      const addressValid = this.address.length >= 6 && this.address.length <= 100
      const postalCodeValid = this.postalCode.length > 0 && /^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/.test(this.postalCode)
      const cityValid = this.city.length > 0 && this.city.length <= 100
      const telephoneValid = this.telephone.length > 0 && /^[769][0-9]{8}$/.test(this.telephone)
      const provinceValid = this.province.length > 0;

      let errorMsg = "";

      if (!nameValid)
        errorMsg += "El nombre debe tener al menos 6 caracteres y no puede sobrepasar los 50 caracteres\n"

      if (!emailValid)
        errorMsg += "El email no es válido\n"

      if (!addressValid)
        errorMsg += "La dirección no es valida, debe tener al menos 6 caracteres y no puede sobrepasar los 100 caracteres\n"

      if (!postalCodeValid)
        errorMsg += "El codigo postal no es válido\n"

      if (!cityValid)
        errorMsg += "La ciudad es un campo obligatorio y no puede sobrepasar els 100 caracteres\n"

      if (!telephoneValid)
        errorMsg += "El número de teléfono no es válido\n"

      if (!provinceValid)
        errorMsg += "El campo de la provincia no es correcto.\n"


      if (this.valid && nameValid && emailValid && addressValid && postalCodeValid && cityValid && telephoneValid && provinceValid) {
        this.loading = true;
        await this.createPaymentIntent();
        await this.setStripeElements();
        this.$emit('update:step', 1);
        this.loading = false;


        const address = `${this.name.trim()}, ${this.address}, ${this.postalCode}, ${this.city}`;
        const shipping = `${this.shippings[this.selectedShipping].title}: ${this.shippings[this.selectedShipping].desc}`

        this.$emit('update:paymentInfo', {
          id: this.intent_id,
          name: this.name.trim(),
          address,
          shipping,
          email: this.email
        })
      } else {
        alert(errorMsg)
      }
    },
    async createPaymentIntent() {


      const address = {
        city: this.city,
        country: "ES",
        line1: this.address,
        postal_code: this.postalCode,
        state: this.province
      }

      const shipping = this.shippings[this.selectedShipping];

      const shippingPrice = Math.round(shipping.price * 100);

      const items = this.items.map(i =>
        ({
          book_id: i.book_id,
          stripe_key: i.stripe_key,
          quantity: i.quantity,
        }));
      if (shippingPrice > 0) {
        // const {data} = await this.axios.get(`/shipping/stripe-key/${shippingPrice.toFixed(2)}/${shipping.Name}`)
        // if (data.length === 0) alert("Si us plau, fes una foto de la pantalla posat en contacte amb examenselectivitat per acabar la teva comanda. contacte@examenselectivitat.cat")

        items.push({
          shipping_id: shipping.id,
          stripe_key: shipping.stripe_key, // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        })
      }


      const {data} = await this.axios.post("/create-payment-intent", {
        items: items,
        customer: {
          // id: this.intent_id,
          name: this.name.trim(),
          address: address,
          email: this.email,
          phone: this.telephone,
          shipping: {
            address: address,
            name: `${this.shippings[this.selectedShipping].title}: ${this.shippings[this.selectedShipping].desc}`
          }
        },
      })

      this.$emit('update:intent_id', data.clientSecret);
    },
    async setStripeElements() {

      const appearance = {
        theme: 'stripe',
      };

      const public_key = process.env.VUE_APP_STRIPE_API_PUBLIC_KEY;
      // const stripe = await Stripe(public_key);
      const stripe = await loadStripe(public_key);

      const elements = stripe.elements({appearance, clientSecret: this.intent_id});

      const paymentElementOptions = {
        layout: "tabs",
      };

      const paymentElement = elements.create("payment", paymentElementOptions);
      paymentElement.mount("#payment-element");

      this.$emit("update:stripe", stripe);
      this.$emit("update:elements", elements);


    },

    // ICONS
    mdiInformation() {
      return mdiInformation
    },
  },
  watch: {
    selectedShipping2(val) {
      this.$emit("update:selectedShipping", val)
    },
    postalCode(val) {
      if (val !== null && val.length >= 5) {
        const postalCodeValid = this.postalCode.length > 0 && /^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/.test(this.postalCode)
        if (!postalCodeValid) {
          setTimeout(() => {
            alert("El codi postal no és valid")
          }, 200)
        }
        // if (postalCodeValid) {
        // 	this.$emit("fetchShippings", val)
        // }
      }
      // else {
      // 	this.shippings.forEach(s => s.price = null);
      // }
    },
    address: debounce(function () {
      const addressHasNumber = this.address.match(/.*\d+\D+\d+\D+\d+.*/)
      console.log("addressHasNumber", addressHasNumber)
      if (!addressHasNumber) {
        this.addressAlert = true;
      }
    }, 3000)
  }
}
</script>

<style scoped>
.element-shadow {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
}
</style>
